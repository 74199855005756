<script setup lang="ts">
import { PropType } from 'vue'

defineProps({
  variant: {
    type: String as PropType<'white' | 'black' | 'yellow'>,
    default: 'white',
  },
  type: {
    type: String as PropType<'tag' | 'category'>,
    default: 'tag',
  },
  slug: {
    type: String,
    required: true,
  },
})

const routes = {
  category: '/search/category/',
  tag: '/search/tag/',
}
</script>

<template>
  <BaseLink
    :href="`${routes[type.toLowerCase()]}${slug}`"
    variant="unstyled"
    class="inline-block cursor-pointer rounded-full"
  >
    <BasePill :variant="variant">
      <slot />
    </BasePill>
  </BaseLink>
</template>
